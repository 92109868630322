











import { Component, Prop, Vue } from 'vue-property-decorator';

import Step from './Step.vue';
import { ReturnFormSections } from '@/models/returnForm';

@Component({
  components: { Step }
})
export default class ProgressBar extends Vue {
  @Prop({ required: true }) readonly step!: ReturnFormSections;

  get isStepOneDone(): boolean {
    return this.step.BASIC_CHECK;
  }
  get isStepTwoDone(): boolean {
    return this.step.CHECK_INSIDE;
  }
  get isStepThreeDone(): boolean {
    return this.step.CHECK_OUTSIDE;
  }
  get isStepFourDone(): boolean {
    return this.step.RETURNING_KEYS;
  }
}
