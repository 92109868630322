









































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { required, requiredIf } from 'vuelidate/lib/validators';
import ClientOnly from 'vue-client-only';
import FileUploadSimple, { UploadIcons } from '@/components/fileUploadSimple.vue';
import {
  getReservationReturnFormPageData,
  Reservation,
  ReturnForm,
  ReturnFormImages,
  ReturnFormImageKey,
  ReturnFormPageData,
  sendReturnForm,
  sendReturnFormImage,
  updateReturnForm,
  FormImage
} from '@/services/reservations.service';
import carsService from '@/services/cars.service';
import Button from '@/components/ui/Button.vue';
import { formatDateCs } from '@/utils/dateTimeUtils';
import ProgressBar from '../components/ui/navbar/progressBar/ProgressBar.vue';
import { MILEAGE_ACCEPTABLE_RANGE_DIFFERENCE_IN_KM, ReturnFormSections } from '../models/returnForm';
import { MapIcon, CheckCircleIcon, RotateCwIcon, PhoneIcon, MailIcon } from 'vue-feather-icons';
import RadioField from '@/components/ui/form/RadioField.vue';
import { debounce } from '@/utils/debounce';
import Tooltip from '@/components/tooltip.vue';
import { numberWithSpaces } from '@/utils/formatNumber';
import userService from '../services/user.service';
import { Permissions } from '../models/roles';

@Component({
  components: {
    FileUploadSimple,
    Button,
    ClientOnly,
    ProgressBar,
    MapIcon,
    PhoneIcon,
    MailIcon,
    CheckCircleIcon,
    RotateCwIcon,
    RadioField,
    Tooltip
  },
  validations: {
    form: {
      parkingOnSamePlace: { required },
      parkingOnSamePlaceOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.parkingOnSamePlace === 'no';
        })
      },
      noOutsideDamage: { required },
      noOutsideDamageOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.noOutsideDamage === 'no';
        })
      },
      lightsOffDoorsLocked: { required },
      lightsOffDoorsLockedOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.lightsOffDoorsLocked === 'no';
        })
      },
      noInsideDamage: { required },
      noInsideDamageOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.noInsideDamage === 'no';
        })
      },
      fullTank: { required },
      fullTankOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.fullTank === 'no';
        })
      },
      keysInsideBox: { required },
      keysInsideBoxOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.keysInsideBox === 'no';
        })
      },
      documentsInsideBox: { required },
      documentsInsideBoxOther: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.documentsInsideBox === 'no';
        })
      },
      outsidePhotos1: { required },
      outsidePhotos2: { required },
      outsidePhotos3: { required },
      outsidePhotos4: { required },
      insidePhotos1: { required },
      insidePhotos2: { required },
      insidePhotos3: { required },
      mileage: { required }
    }
  },
  filters: {
    numberWithSpaces
  }
})
export default class ReturningForm extends Vue {
  public form: ReturnForm = {
    parkingOnSamePlace: '',
    parkingOnSamePlaceOther: '',
    noOutsideDamage: '',
    noOutsideDamageOther: '',
    noInsideDamage: '',
    noInsideDamageOther: '',
    lightsOffDoorsLocked: '',
    lightsOffDoorsLockedOther: '',
    fullTank: '',
    fullTankOther: '',
    keysInsideBox: '',
    keysInsideBoxOther: '',
    documentsInsideBox: '',
    documentsInsideBoxOther: '',
    comments: '',
    mileage: null,
    outsidePhotos1: null,
    outsidePhotos2: null,
    outsidePhotos3: null,
    outsidePhotos4: null,
    insidePhotos1: null,
    insidePhotos2: null,
    insidePhotos3: null,
    additionalPhotos1: null,
    additionalPhotos2: null,
    additionalPhotos3: null,
    additionalPhotos4: null
  };

  public photosLoading: Record<ReturnFormImageKey, boolean> = {
    outsidePhotos1: false,
    outsidePhotos2: false,
    outsidePhotos3: false,
    outsidePhotos4: false,
    insidePhotos1: false,
    insidePhotos2: false,
    insidePhotos3: false,
    additionalPhotos1: false,
    additionalPhotos2: false,
    additionalPhotos3: false,
    additionalPhotos4: false
  };

  returnFormData: ReturnFormPageData | null = null;
  finished = false;
  isSubmitting = false;
  isOnParking = true;
  hasFullTank = true;
  fuelTankInLitres = 0;
  isFormValid: boolean | null = null;
  reservation: Reservation | null = null;
  uploadIcons = UploadIcons;
  progressBarCompletedSections: ReturnFormSections = {
    BASIC_CHECK: false,
    CHECK_INSIDE: false,
    CHECK_OUTSIDE: false,
    RETURNING_KEYS: false
  };
  allSectionsCompleted = false;
  showMileageWarning = false; // Control flag for showing the mileage warning
  warningParagraphHeight = 0; // Height of the warning paragraph

  isAdmin = false;

  get reservationId(): string {
    return this.$route.query.id as string;
  }

  get sectionsCompleted(): ReturnFormSections {
    if (this.form) {
      this.progressBarCompletedSections.BASIC_CHECK = !!(
        (this.form.parkingOnSamePlace === 'yes' || this.form.parkingOnSamePlaceOther) &&
        (this.form.fullTank === 'yes' || this.form.fullTankOther)
      );
      this.progressBarCompletedSections.CHECK_INSIDE = !!(
        this.form.insidePhotos1 &&
        this.form.mileage &&
        this.form.insidePhotos2 &&
        (this.form.noInsideDamage === 'yes' || this.form.noInsideDamageOther) &&
        (this.form.documentsInsideBox === 'yes' || this.form.documentsInsideBoxOther)
      );
      this.progressBarCompletedSections.CHECK_OUTSIDE = !!(
        this.form.outsidePhotos1 &&
        this.form.outsidePhotos2 &&
        this.form.outsidePhotos3 &&
        this.form.outsidePhotos4 &&
        this.form.insidePhotos3 &&
        (this.form.noOutsideDamage === 'yes' || this.form.noOutsideDamageOther) &&
        (this.form.lightsOffDoorsLocked === 'yes' || this.form.lightsOffDoorsLockedOther)
      );
      this.progressBarCompletedSections.RETURNING_KEYS = !!(
        this.form.keysInsideBox === 'yes' || this.form.keysInsideBoxOther
      );
      this.allSectionsCompleted =
        this.progressBarCompletedSections.BASIC_CHECK &&
        this.progressBarCompletedSections.CHECK_INSIDE &&
        this.progressBarCompletedSections.CHECK_OUTSIDE &&
        this.progressBarCompletedSections.RETURNING_KEYS;
    }

    return this.progressBarCompletedSections;
  }

  get useUpdateReturnForm(): boolean {
    if (!this.returnFormData || !this.returnFormData?.returnForm) {
      return false;
    }
    return this.mandatoryReturnFormDataFilledOut(this.returnFormData.returnForm) && this.isAdmin;
  }

  // Determine if new mileage should be used
  get useNewMileage(): boolean {
    // For newly added cars that don't have any mileage (past RForm filled in) yet
    if (this.returnFormData && !this.returnFormData.carLastKnownMileage) {
      return true;
    }
    return (
      !!this.form.mileage &&
      !!this.returnFormData &&
      !!this.returnFormData.carLastKnownMileage &&
      // Determine if the input mileage is within an acceptable range:
      // If difference between the input mileage and the last known car mileage is <= 10k km
      Math.abs(this.form.mileage - this.returnFormData.carLastKnownMileage) <= MILEAGE_ACCEPTABLE_RANGE_DIFFERENCE_IN_KM
    );
  }

  // Event handler: Triggered when mileage input loses focus
  public handleBlur() {
    this.showMileageWarning = !this.useNewMileage;
  }
  // Event handler: Triggered when mileage input gains focus
  public handleFocus() {
    this.showMileageWarning = false;
  }
  // Event handler: Triggered on mileage input value change
  public handleMileageInput(data: string) {
    this.showMileageWarning = false;
    localStorage.setItem('return-mileage', data);
  }

  @Watch('showMileageWarning')
  onShowMileageWarningChange() {
    this.updateWarningParagraphHeight();
  }

  // vue is love https://stackoverflow.com/questions/62729380/vue-watch-outputs-same-oldvalue-and-newvalue
  // to be able watch new vs old value in the object it needs to be recreated each time it changes
  // breaking the reference of object keys (@Watch('form') returns the same old and new values)
  // reasoning behind is updating only changed value.
  get formToBeWatched() {
    return { ...this.form };
  }

  @Watch('formToBeWatched', { deep: true })
  onFormChange(currentForm: ReturnForm, oldForm: ReturnForm) {
    Object.keys(currentForm).forEach((key) => {
      if (currentForm[key] !== oldForm[key] && typeof currentForm[key] === 'string') {
        localStorage.setItem(`return-${key}`, currentForm[key] as string);
      }
    });
  }

  // Update warning paragraph height
  updateWarningParagraphHeight() {
    this.$nextTick(() => {
      const paragraphElement = this.$refs.paragraph as HTMLElement;
      this.warningParagraphHeight = paragraphElement ? paragraphElement.offsetHeight : 0;
    });
  }

  getDocumentPath(existingImage: FormImage): string | null {
    return existingImage && typeof existingImage.path === 'string' ? existingImage.path : null;
  }

  // Get Dynamic paragraph height style value
  get dynamicWarningParagraphHeight() {
    return this.showMileageWarning ? 'none' : this.warningParagraphHeight + 'px';
  }

  get formatedEndDate(): string {
    if (!this.returnFormData) {
      return '';
    }
    const date = this.returnFormData.end;

    // TODO (TZ) Display actual finish time
    return `vypůjčeno do ${formatDateCs(date)}, 23:59`;
  }

  get isLoading(): boolean {
    return this.isSubmitting || this.imageLoading;
  }

  get imageLoading(): boolean {
    return Object.values(this.photosLoading).some((val) => val === true);
  }

  async mounted() {
    await new Promise<void>((resolve) => {
      this.$watch('$store.state.fetchingLoggedUser', (newVal) => {
        if (!newVal) {
          // When logged user stops being fetched (=value updates)
          resolve();
        }
      });
    });

    this.isAdmin = await userService.isAllowedTo(Permissions.reservationReturnformManage);
    this.updateWarningParagraphHeight();
    this.checkLocalStorage();
    this.populateFormFromLocalStorage();
    if (!this.reservationId) {
      this.$router.push('/');
      return;
    }
    this.getReservationAndCarData();
  }

  private async getReservationAndCarData() {
    try {
      await this.getReservationReturnFormData();
      if (!this.returnFormData) {
        throw new Error('Car id is not provided');
      }
      await this.getAndSetIsOnParking(this.returnFormData.carId, false);
    } catch (err) {
      console.error(err);
      return;
    }
  }

  private debouncedGetAndSetIsOnParking = debounce((carId: string, showSuccessModal: boolean) => {
    return this.getAndSetIsOnParking(carId, showSuccessModal);
  });

  async getAndSetIsOnParking(carId: string, showSuccessModal: boolean) {
    try {
      this.isOnParking = await carsService.isOnParking(carId);
    } catch (err) {
      this.$toast.error('Nepodařilo se aktualizovat polohu.');
      throw err;
    }
    // if the user sects no + reloads the form then we keep the no even the tracking says yes
    if (localStorage.getItem('return-parkingOnSamePlace') !== 'no') {
      this.form.parkingOnSamePlace = this.isOnParking ? 'yes' : 'no';
    }
    if (showSuccessModal) {
      this.$toast.success('Poloha byla úspěšně aktualizována.');
    }
  }

  private checkLocalStorage() {
    if (this.reservationId !== localStorage.getItem('return-lastReservationId')) {
      this.pruneLocalStorage();
      localStorage.setItem('return-lastReservationId', this.reservationId);
    }
  }
  private populateFormFromLocalStorage() {
    if (this.form) {
      Object.keys(this.form).forEach((key) => {
        const value = localStorage.getItem(`return-${key}`);
        if (value) {
          this.form[key] = value;
        }
      });
    }
  }

  private pruneLocalStorage() {
    Object.keys(this.form).forEach((key) => {
      localStorage.removeItem(`return-${key}`);
    });
  }

  private async getReservationReturnFormData() {
    try {
      this.returnFormData = await getReservationReturnFormPageData(this.reservationId);
      if (!this.returnFormData.returnForm) {
        this.returnFormData.returnForm = this.form;
      }
      if (
        this.returnFormData.returnForm &&
        (this.isAdmin || !this.mandatoryReturnFormDataFilledOut(this.returnFormData.returnForm))
      ) {
        if (this.returnFormData.fuelTankInLitres) {
          this.fuelTankInLitres = this.returnFormData.fuelTankInLitres;
          //5% tolerance
          const ERROR_TOLERANCE = 1.05;
          this.hasFullTank =
            this.returnFormData.fuelTankInLitres >= this.returnFormData.fuelTankCapacityInLitres / ERROR_TOLERANCE;
          if (this.returnFormData.returnForm.fullTank === '') {
            this.returnFormData.returnForm.fullTank = !this.hasFullTank ? 'no' : '';
          }
        }
        this.form = { ...this.form, ...this.returnFormData.returnForm };
      } else if (!this.isAdmin && this.mandatoryReturnFormDataFilledOut(this.returnFormData.returnForm)) {
        this.$toast.error('Formulář nelze vyplnit vícekrát');
        this.$router.push('/');
      }
    } catch (err) {
      this.$toast.error((err as Error).message);
      this.$router.push('/');
    }
  }

  async submit() {
    if (!this.useNewMileage && this.returnFormData) {
      this.form.mileage = this.returnFormData.carLastKnownMileage;
    }
    this.$v.form.$touch();
    if (!this.$v.form.$invalid && this.returnFormData) {
      try {
        this.isSubmitting = true;
        if (this.useUpdateReturnForm) {
          await updateReturnForm(this.reservationId, this.form);
        } else {
          await sendReturnForm(this.reservationId, this.form, this.fuelTankInLitres);
        }
        this.isSubmitting = false;
        this.pruneLocalStorage();
        this.finished = true;
        window.scrollTo(0, 0);
      } catch (e) {
        this.isSubmitting = false;
        this.$toast.error((e as Error).message);
      }
    }
  }

  async uploadImage(image: ReturnFormImages) {
    if (Object.values(image).every((val) => val !== null)) {
      const key = Object.keys(image)[0] as ReturnFormImageKey;
      this.photosLoading[key] = true;
      try {
        const res = await sendReturnFormImage(this.reservationId, image);
        if (this.returnFormData) {
          const currentReturnFormImages: ReturnFormImages = {
            insidePhotos1: this.form.insidePhotos1,
            insidePhotos2: this.form.insidePhotos2,
            insidePhotos3: this.form.insidePhotos3,
            outsidePhotos1: this.form.outsidePhotos1,
            outsidePhotos2: this.form.outsidePhotos2,
            outsidePhotos3: this.form.outsidePhotos3,
            outsidePhotos4: this.form.outsidePhotos4,
            additionalPhotos1: this.form.additionalPhotos1,
            additionalPhotos2: this.form.additionalPhotos2,
            additionalPhotos3: this.form.additionalPhotos3,
            additionalPhotos4: this.form.additionalPhotos4,
            ...res
          };
          this.returnFormData.returnForm = {
            ...this.returnFormData.returnForm,
            ...currentReturnFormImages
          };
          this.form = {
            ...this.form,
            ...currentReturnFormImages
          };
        }
      } catch (err) {
        this.$toast.error((err as Error).message);
        console.error(err);
      }
      this.photosLoading[key] = false;
    }
  }

  private mandatoryReturnFormDataFilledOut(returnForm: ReturnForm): boolean {
    return (
      !!returnForm &&
      !!(
        (returnForm.parkingOnSamePlace || returnForm.parkingOnSamePlaceOther) &&
        (returnForm.noOutsideDamage || returnForm.noOutsideDamageOther) &&
        (returnForm.lightsOffDoorsLocked || returnForm.lightsOffDoorsLockedOther) &&
        (returnForm.noInsideDamage || returnForm.noInsideDamageOther) &&
        (returnForm.fullTank || returnForm.fullTankOther) &&
        (returnForm.keysInsideBox || returnForm.keysInsideBoxOther) &&
        (returnForm.documentsInsideBox || returnForm.documentsInsideBoxOther) &&
        returnForm.mileage
      )
    );
  }
}
